import { inject } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { AppUpdateComponent } from '@shared/components/app-update/app-update.component';

export function createAppUpdateModal() {
  const modalCtrl = inject(ModalController);
  return async function () {
    const modal = await modalCtrl.create({
      component: AppUpdateComponent,
      componentProps: {
        closeModal: () => {
          modal.dismiss();
        },
      },
      cssClass: 'auto-height',
      backdropDismiss: false,
      canDismiss: true,
    });
    await modal.present();
  };
}
