import { Component, inject, Input, OnInit } from '@angular/core';
import { IonButton } from '@ionic/angular/standalone';
import { HessaBtnDirective } from '@ui-kit/hes-button/hes-button.directive';
import { AppUpdateService } from '@core/app-update.service';
import { ModalController } from '@ionic/angular';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.component.html',
  imports: [IonButton, HessaBtnDirective, TranslocoDirective],
  standalone: true,
})
export class AppUpdateComponent implements OnInit {
  private readonly appUpdateService = inject(AppUpdateService);
  @Input() private closeModal: () => void;

  constructor() {}

  ngOnInit() {}

  onUpdate() {
    this.appUpdateService.openUpdatePage();
  }

  onCancel() {
    this.closeModal?.();
  }
}
