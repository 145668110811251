<div class="p-6" *transloco="let t">
  <div class="mb-6 mt-4 text-base uppercase text-gray-600">
    {{ t("global.new_update.label") }}
  </div>
  <div class="text-xl font-black">
    {{ t("global.latest_version_update.title") }}
  </div>
  <div class="mt-4 text-base text-gray-600">
    {{ t("global.new_update.txt") }}
  </div>

  <div class="mt-8">
    <div>
      <ion-button
        hesBtn
        hesColor="primary"
        class="w-full font-bold"
        (click)="onUpdate()"
        >{{ t("global.update_now.btn") }}</ion-button
      >
    </div>
    <div class="mt-2">
      <ion-button hesBtn hesColor="gray" class="w-full" (click)="onCancel()">{{
        t("global.cancel.btn")
      }}</ion-button>
    </div>
  </div>
</div>
